<template>
  <div class="entertainmentNew">
    <!--        <div class="headBar">-->
    <!--            <div class="headTitle">-->
    <!--                <span>娱乐</span>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <iframe v-if="url" class="content" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" :src="url"></iframe>-->
    <!-- <div class="bg"></div> -->
    <!-- <Header title="娱乐"/> -->
    <div class="header flex-center-center">娱乐</div>

    <van-tabs background="transparent">
      <van-tab
        v-if="
          this.shuHengApp.length ||
          this.AppAdvArr.length ||
          this.shuAppList.length
        "
        title="应用"
      >
        <div class="content">
          <div class="img-container" v-if="this.AppAdvArr.length">
           <van-swipe class="swiper" :autoplay="3000" indicator-color="#FECD55">
            <van-swipe-item
              v-for="(item, index) in AppAdvArr"
              :key="index"
              @click="toJump(item, 1)"
            >
             <ImgDecypt :src="item.image" :key="index"/>
            </van-swipe-item>
           </van-swipe>
<!--            <div-->
<!--              v-for="(item, index) in AppAdvArr"-->
<!--              @click="toJump(item, 1)"-->
<!--              :key="index"-->
<!--            >-->
<!--              <ImgDecypt :key="index" class="avatar" :src="item.image" />-->
<!--            </div>-->
          </div>
          <div class="recommend" v-if="this.shuHengApp.length">
            <div class="title">
              <div class="line"></div>
              <span style="color: #333333; font-size: 16px">官方推荐</span>
            </div>
            <div class="appList">
              <div
                class="appItem"
                v-for="(item, index) in shuHengApp"
                :key="index"
                @click="toJump(item, 2)"
              >
                <div class="app-mess">
                  <ImgDecypt
                    :key="item.icon"
                    class="app-avatar"
                    :src="item.icon"
                  />
                  <div class="mess">
                    <p>{{ item.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title">
            <div class="line"></div>
            <span style="color: #333333; font-size: 16px">热门应用</span>
          </div>
          <div class="list">
            <div
              class="list-item"
              v-for="(item, index) in shuAppList"
              :key="index"
            >
              <div class="app-mess">
                <ImgDecypt
                  :key="item.icon"
                  class="app-avatar"
                  :src="item.icon"
                />
                <div class="mess">
                  <p>{{ item.name }}</p>
                </div>
              </div>
              <div class="btn flex-center-center" @click="toJump(item, 2)">
                立即下载
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab
        v-if="this.gameList.length || this.gameAdvArr.length"
        title="游戏"
      >
        <div class="content">
          <div class="img-container" v-if="this.gameAdvArr.length">
            <van-swipe class="swiper" :autoplay="3000" indicator-color="#FECD55">
             <van-swipe-item
               v-for="(item, index) in gameAdvArr"
               :key="index"
               @click="toJump(item, 1)"
             >
              <ImgDecypt :src="item.image" :key="index"/>
             </van-swipe-item>
            </van-swipe>
<!--            <div-->
<!--              v-for="(item, index) in gameAdvArr"-->
<!--              @click="toJump(item, 1)"-->
<!--              :key="index"-->
<!--            >-->
<!--              <ImgDecypt :key="index" class="avatar" :src="item.image" />-->
<!--            </div>-->
          </div>
          <div class="hotContent">
            <div class="title">
              <div class="line"></div>
              <span style="color: #333333; font-size: 16px">热门推荐</span>
            </div>
            <div class="gameList">
              <div
                class="gameItem"
                v-for="(item, index) in gameList"
                :key="index"
                @click="toJump(item, 2)"
              >
                <div class="app-mess">
                  <ImgDecypt
                    :key="item.icon"
                    class="app-avatar"
                    :src="item.icon"
                  />
                  <div class="mess">
                    <p>{{ item.name }}</p>
                    <!--                下载次数：{{ watchCount(item.downloadNum) || 0 }}次-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab v-if="this.ypList.length || this.ypAdvArr.length" title="约炮">
        <div class="content">
          <div class="img-container" v-if="this.ypAdvArr.length">
           <van-swipe class="swiper" :autoplay="3000" indicator-color="#FECD55">
            <van-swipe-item
              v-for="(item, index) in ypAdvArr"
              :key="index"
              @click="toJump(item, 1)"
            >
             <ImgDecypt :src="item.image" :key="index"/>
            </van-swipe-item>
           </van-swipe>
          </div>
          <div class="hotContent">
            <div class="title">
              <div class="line"></div>
              <span style="color: #333333; font-size: 16px">热门推荐</span>
            </div>
            <div class="gameList">
              <div
                class="gameItem"
                v-for="(item, index) in ypList"
                :key="index"
                @click="toJump(item, 2)"
              >
                <div class="app-mess">
                  <ImgDecypt
                    :key="item.icon"
                    class="app-avatar"
                    :src="item.icon"
                  />
                  <div class="mess">
                    <p>{{ item.name }}</p>
                    <!--                下载次数：{{ watchCount(item.downloadNum) || 0 }}次-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab v-if="this.qpList.length || this.qpAdvArr.length" title="棋牌">
        <div class="content">
          <div class="img-container" v-if="this.qpAdvArr.length">
           <van-swipe class="swiper" :autoplay="3000" indicator-color="#FECD55">
            <van-swipe-item
              v-for="(item, index) in qpAdvArr"
              :key="index"
              @click="toJump(item, 1)"
            >
             <ImgDecypt :src="item.image" :key="index"/>
            </van-swipe-item>
           </van-swipe>
          </div>
          <div class="hotContent">
            <div class="title">
              <div class="line"></div>
              <span style="color: #333333; font-size: 16px">热门推荐</span>
            </div>
            <div class="gameList">
              <div
                class="gameItem"
                v-for="(item, index) in qpList"
                :key="index"
                @click="toJump(item, 2)"
              >
                <div class="app-mess">
                  <ImgDecypt
                    :key="item.icon"
                    class="app-avatar"
                    :src="item.icon"
                  />
                  <div class="mess">
                    <p>{{ item.name }}</p>
                    <!--                下载次数：{{ watchCount(item.downloadNum) || 0 }}次-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab v-if="this.zbList.length || this.zbAdvArr.length" title="直播">
        <div class="content">
          <div class="img-container" v-if="this.zbAdvArr.length">
           <van-swipe class="swiper" :autoplay="3000" indicator-color="#FECD55">
            <van-swipe-item
              v-for="(item, index) in zbAdvArr"
              :key="index"
              @click="toJump(item, 1)"
            >
             <ImgDecypt :src="item.image" :key="index"/>
            </van-swipe-item>
           </van-swipe>
<!--            <div-->
<!--              v-for="(item, index) in zbAdvArr"-->
<!--              @click="toJump(item, 1)"-->
<!--              :key="index"-->
<!--            >-->
<!--              <ImgDecypt :key="index" class="avatar" :src="item.image" />-->
<!--            </div>-->
          </div>
          <div class="hotContent">
            <div class="title">
              <div class="line"></div>
              <span style="color: #333333; font-size: 16px">热门推荐</span>
            </div>
            <div class="gameList">
              <div
                class="gameItem"
                v-for="(item, index) in zbList"
                :key="index"
                @click="toJump(item, 2)"
              >
                <div class="app-mess">
                  <ImgDecypt
                    :key="item.icon"
                    class="app-avatar"
                    :src="item.icon"
                  />
                  <div class="mess">
                    <p>{{ item.name }}</p>
                    <!--                下载次数：{{ watchCount(item.downloadNum) || 0 }}次-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <!-- <Loading v-else /> -->
  </div>
</template>

<script>
import { getRecreatio, upperRecreatio } from "@/api/movies";
import Loading from "@/components/Loading";
import ImgDecypt from "@/components/ImgDecypt";
import { getDevType } from "@/utils";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";

export default {
  name: "index",
  components: {
    // Loading,
    ImgDecypt,
  },
  data() {
    return {
      // url: null
      loading: true,
      appAdvList: [],
      gameAdvList: [],
      YPAdvList: [],
      QPAdvList: [],
      ZBAdvList: [],
      shuHengApp: [],
      shuAppList: [],
      gameList: [],
      ypList: [],
      zbList: [],
      qpList: [],
      AppAdvArr: [],
      gameAdvArr: [],
      ypAdvArr: [],
      qpAdvArr: [],
      zbAdvArr: [],
    };
  },
  mounted() {
    this.gameAdvList = getAdItem(AdType.RECGAME);
    this.YPAdvList = getAdItem(AdType.RECYP);
    this.QPAdvList = getAdItem(AdType.RECQP);
    this.ZBAdvList = getAdItem(AdType.RECZB);
    this.getList();
  },
  methods: {
    async toJump(data, types) {
      try {
        window.open(data.url);
        let req = {
          id: data.id, //点击id
          type: types == 1 ? "adv" : data.type, //广告类型. app 或 adv
          sysType: getDevType(), // 设备类型. android或ios
        };

        let res = await this.$Api(upperRecreatio, req);
      } catch (e) {
        console.log(e);
      }
    },
    async getList() {
      try {
        let res = await this.$Api(getRecreatio, {});
        const { data } = res;
        console.log(res);
        this.shuAppList = data.shuApp || [];
        this.appAdvList = data.adv || [];
        this.shuHengApp = data.hengApp || [];
        this.gameList = data.gameApp || [];
        this.ypList = data.ypApp || [];
        this.zbList = data.zbApp || [];
        this.qpList = data.qpApp || [];
        this.filterList();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    filterList() {
      for (let i = 0; i < this.appAdvList.length; i++) {
        const element = this.appAdvList[i];
        if (element.moduleType == 1) {
          element.cover = element.image;
          this.AppAdvArr.push(element);
        } else if (element.moduleType == 2) {
          element.cover = element.image;
          this.gameAdvArr.push(element);
         console.log(this.gameAdvArr);
        } else if (element.moduleType == 3) {
          element.cover = element.image;
          this.ypAdvArr.push(element);
        } else if (element.moduleType == 4) {
          element.cover = element.image;
          this.qpAdvArr.push(element);
        } else if (element.moduleType == 5) {
          element.cover = element.image;
          this.zbAdvArr.push(element);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.entertainmentNew {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);

  /deep/ .van-tabs__wrap {
    background: transparent;
    width: 100%;
    margin-left: 12px;
    white-space: nowrap;
  }
  /deep/ .van-tabs__line {
    background: transparent;
    width: 22px;
    height: 3px;
    background-image: url("~@/assets/png/homeTags.png") !important;
    // background: linear-gradient(to right, rgba(255, 255, 255, 0) 5%, rgba(253, 5, 99, 1) 60%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 20px;
  }

  /deep/ .van-tabs__nav {
    width: 70%;
    display: grid !important;
    grid-template-columns: repeat(5, 1fr) !important;
    .van-tab__text {
      font-size: 14px;
      color: #333333;
    }
  }

  /deep/ .van-tab--active {
    .van-tab__text {
      font-size: 20px;
      color: #333333;
    }
  }

  .content {
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding-bottom: 200px !important;
  }
  .content::after {
    content: "";
    display: block;
    width: 100%;
    height: 200px;
  }

  .swiper {
    overflow: hidden;
    width: 343px;
    //width: 100%;
    height: 143px;
    margin: 4px auto;
    margin-bottom: 12px;
    box-sizing: border-box;
    /deep/ .van-swipe-item {
      width: 343px !important;
      height: 143px !important;
    }
    /deep/ .van-swipe__indicators {
      background: none !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
    /deep/ .van-swipe__indicator {
      width: 8px !important;
      height: 8px !important;
      background-color: rgba(148,214,248, 0.45) !important;
      opacity: 0.4 !important;
    }
    /deep/ .van-swipe__indicator--active {
      width: 10px !important;
      height: 10px !important;
      background-color: #94D6DA !important;
      opacity: 1 !important;
    }
   /deep/ .van-image__img {
    border-radius: 8px;
    object-fit: fill !important;
   }
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: rgb(51, 51, 51);
    font-weight: bold;

    .line {
      width: 4px;
      height: 22px;
      background: rgba(251, 209, 61, 1);
      border-radius: 14.5px;
      margin-right: 12px;
    }
  }
  .appList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 19px;
    grid-column-gap: 30px;
    padding: 0 16px;
    margin-top: 12px;
    margin-bottom: 14px;
    border-bottom: 1px solid #e6e6e6;

    .appItem {
      .mess {
        text-align: center;
        font-size: 12px;
        color: #333333;
        margin-top: 6px;
      }
    }
  }
  .gameList {
    padding: 0 16px;
    margin-top: 12px;

    .gameItem {
      margin-bottom: 12px;

      .app-avatar {
        border-radius: 4px;
        width: calc(100vw - 32px);
        height: calc((100vw - 32px) / 3.6);
      }

      .mess {
        font-size: 12px;
        color: #333333;
        margin-top: 6px;
      }
    }
  }

  .list {
    margin-top: 18px;

    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //padding: 0 16px;
      padding: 0 16px 15px 16px;
      //margin-bottom: 15px;
      margin-top: 15px;
      border-bottom: solid 1px #e6e6e6;

      .app-mess {
        display: flex;
        align-items: center;

        .app-avatar {
          width: 50px;
          height: 50px;
          border-radius: 4px !important;
          margin-right: 10px;
        }

        .mess {
          font-size: 12px;
          color: #333333;
        }
      }

      .btn {
        width: 72px;
        height: 28px;
        line-height: 28px;
        background: rgba(251, 209, 61, 1);
        border-radius: 18px;
        font-size: 12px;
        color: rgba(66, 66, 66, 1);
      }
    }
  }
  .header {
    width: 100%;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 44px;
    background-color: #ffffff;
    color: #333333;
  }
  .main {
    height: calc(100vh - 64px);
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    .img-container {
      width: 100%;
      max-height: 305px;
      .avatar {
        width: 100%;
        height: 93px;
        border-radius: 10px;
        overflow: hidden;
        margin: 0 0 12px 0;
      }
    }
    .title {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 20px 0;
      span {
        display: inline-block;
        width: 8px;
        height: 21px;
        background: #94d6da;
        border-radius: 5px;
        margin-right: 3px;
      }
    }
  }
}
</style>
